'use strict'

import { Flex, Img, Link } from 'smbls'

export const Person = {
  extend: [Link, Flex],

  props: ({ state }) => ({
    href: state.linkedin,
    target: '_blank',
    display: 'block',
    flow: 'column',
    align: 'center flex-start',
    gap: 'Z',
    textAlign: 'center',

    ':hover': {
      style: {
        h6: { textDecoration: 'underline' }
      }
    },

    '@mobileL': {
      margin: '-X 0 0',
      minWidth: '100%',
      flexDirection: 'column',
      align: 'center center'
    }
  }),

  avatar: {
    extend: Img,
    if: ({ state }) => state.poster,
    props: ({ state }) => ({
      src: state.poster,
      widthRange: 'D2',
      aspectRatio: '1 / 1',
      padding: '0',
      round: 'C1'
    })
  },

  title: {
    props: {
      padding: '0',
      margin: '0',
      lineHeight: '1.3',
      '@mobileL': {
        textAlign: 'center'
      },
      name: {
        fontWeight: '600',
        fontSize: 'A',
        whiteSpace: 'nowrap',
        margin: '0',
        '@mobileL': {
        }
      },
      role: {
        fontSize: 'Z1',
        fontWeight: '400',
        opacity: '.65',
        '@mobileL': {
          fontSize: 'Z'
        }
      }
    },

    name: {
      tag: 'h6',
      text: ({ state }) => state.name
    },
    role: {
      text: ({ state }) => state.role
    }
  }
}
