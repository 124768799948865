'use strict'

import { Link } from 'smbls'

export const Footer = {
  extend: Link,

  props: {
    href: 'https://calendly.com/mariam-sum/lets-talk-4it-4-you',
    target: '_blank',

    display: 'block',
    padding: 'C C2',
    margin: 'C2 V 0',

    theme: 'primary',
    transition: 'background B',

    '@mobileL': {
      padding: 'C B1 B',
      margin: 'D 0 0'
    },

    h2: {
      margin: 'C2 0 0 0',
      fontWeight: '700',
      '@mobileL': { fontSize: 'G', margin: '2.1em 0 0 0' }
    },

    p: {
      margin: '0',
      opacity: '75%',
      fontWeight: '500',

      '@mobileL': {
        text: 'Book a free 15-30 minutes call with Mariam'
      }
    }
  },

  h2: {
    Icon: {
      fontSize: 'X2',
      margin: '- X2 - -',
      name: 'arrow'
    },
    text: 'Book a Call'
  },
  p: { text: '30 Minutes Call with Mariam' }
}
