'use strict'

import { Article } from '../../components'

export const RPO = {
  extend: Article,

  state: 'rpo',

  header: {},

  content: {
    ArticleHeading: {},

    ArticleSection_what_is_rpo: {
      state: 'what_is_rpo'
    },

    ArticleSection_belief: {
      state: 'belief'
    },

    ArticleList_highlights: {
      state: 'highlights',
      heading: null,
      props: { margin: '-B 0 0' }
    },

    ArticleList_highlights_why_choose: {
      state: 'highlights_why_choose'
    },

    ArticleSection_goal: {
      state: 'goal'
    },

    ArticleList_highlights_rpo: {
      state: 'highlights_rpo'
    },

    ArticleHeadline: {
      state: 'headline',
      $stateCollection: ({ state }) => state.parse()
    }
  },

  ArticleFooter: {},
  OtherServices: {},
  FooterNav: {}
}
