'use strict'

import {
  Article,
  ArticleSection,
  Person
} from '../../components'

export const About = {
  extend: Article,

  state: 'about',

  header: {},

  content: {
    props: { minHeight: '65vh' },

    ArticleHeading: {},

    Flex: {
      props: { flow: 'column', gap: 'C1' },
      childExtend: ArticleSection,
      $stateCollection: ({ state }) => state.data
    },

    Flex_recruiters: {
      state: 'recruiters',
      props: {
        flow: 'column',
        gap: 'B'
      },

      ArticleSection: {
        props: {
          margin: 'Z 0 0',
          fontSize: 'A2'
        },
        h1: ({ state }) => state.title
      },

      Grid: {
        props: {
          gap: 'C',
          maxWidth: 'I2',
          columns: 'repeat(4, 1fr)',

          '@tabletS': { columns: 'repeat(3, 1fr)' },
          '@mobileM': { columns: 'repeat(2, 1fr)' }
        },
        childExtend: Person,
        $stateCollection: ({ state }) => state.data
      }
    }
  },

  ArticleFooter: {},
  FooterNav: {}
}
