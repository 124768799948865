'use strict'

import { Flex, Link } from 'smbls'

const JobItem = {
  extend: [Flex, Link],

  props: ({ state }) => ({
    flow: 'column',
    padding: 'C C2 C2',
    margin: '0 -C2',
    href: state.href,
    target: state.href && state.href.includes('http') ? '_blank' : undefined,
    color: 'currentColor',
    transition: 'background C, color B',

    '@dark': { borderBottom: '1px, solid, white .1' },
    '@light': { borderBottom: '1px, solid, #E9E9E9' },

    '@mobileM': {
      padding: 'C A2',
      margin: '0 -A2'
    },

    ':last-child': { borderBottom: 'none' },

    ':hover': {
      style: { h1: { color: 'white' } },

      '@dark': {
        background: 'black .35',
        color: 'white'
      },
      '@light': {
        background: 'black',
        color: 'white'
      }
    },

    title: {
      margin: '0',
      fontSize: 'M',

      '@dark': {
        color: 'gray8',
        textStroke: '1px, white .35'
      },
      '@light': {
        color: 'white',
        textStroke: '1px, black'
      },

      '@mobileM': {
        fontSize: 'H',
        lineHeight: 1.2,
        margin: '0 0 X'
      }
    },

    location: {
      color: 'currentColor',
      opacity: 0.65,
      fontWeight: 'normal'
    }
  }),

  title: { text: ({ state }) => state.title },
  location: { text: ({ state }) => state.description }
}

export const Hiring = {
  state: 'hiring',

  Header: {},

  content: {
    props: {
      padding: 'D1 E1',
      '@mobileM': { padding: 'C A2' }
    },

    ArticleHeading: {
      margin: '0 0 B',
      '@mobileL': { margin: '0' },
      h1: { '@mobileL': { fontSize: 'F' } }
    },

    list: {
      state: 'data',
      childExtend: JobItem,
      $stateCollection: ({ state }) => state.parse()
    },

    Testimonials: {
      state: '../candidate_testimonials',
      $stateCollection: ({ state }) => state.parse()
    }
  },

  FooterNav: {}
}
