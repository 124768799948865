'use strict'

import { Article } from '../../components'

export const Recruiting = {
  extend: Article,

  state: 'recruiting',

  header: {},

  content: {
    ArticleHeading: {},

    ArticleParagraphs: {
      state: 'paragraphs'
    },

    ArticleHeadline: {
      props: {},
      ...[
        'We deliver great talent at speed, with the minimum amount of effort from you and with a completely',
        {
          text: ' transparent pricing model.',
          props: { fontWeight: 'bold' }
        }
      ]
    },

    ArticleHighlights: {
      state: 'highlights'
    },

    ArticleSection: {
      state: 'goal'
    }
  },

  ArticleFooter: {},
  OtherServices: {},
  FooterNav: {}
}
