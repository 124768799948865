'use strict'

export const Main = {
  props: {
    content: {
      padding: 'C2 C2 0',
      '@mobileL': { padding: '0 B' }
    }
  },

  Header: {},

  content: {
    state: 'main',

    Hero: {},
    ResearchCards: {},
    Cards: {},
    Partners: {},
    MapSection: {},
    Testimonials: { state: '../client_testimonials' },
    Proposition: {}
  },

  Footer: {},
  FooterNav: {}
}
