'use strict'

import { Flex, Pseudo } from 'smbls'

const Item = {
  props: {
    widthRange: 'F1',
    target: '_blank',

    ':any-link:hover': {
      // style: { '& p': { textDecoration: 'underline' } }
      style: { '& > p:first-child': { textDecoration: 'underline' } }
    },

    title: {
      fontWeight: '700',
      margin: '0',
      lineHeight: '1.563'
    },

    description: {
      margin: '0',
      fontWeight: '400',
      lineHeight: '1.563',
      '@light': { color: 'grey25' },
      '@dark': { color: 'white .5' }
    }
  },

  childExtend: { tag: 'p' },

  title: { text: ({ state }) => state.title },
  description: { text: ({ state }) => state.description }
}

export const ArticleHighlights = {
  props: {
    title: {
      fontSize: 'E2',
      fontWeight: '700',
      margin: '0 0 A'
    },
    reasons: {
      maxWidth: 'I2',
      wrap: 'wrap',
      gap: 'C'
    }
  },

  title: {
    tag: 'h2',
    if: ({ state }) => state.title,
    text: ({ state }) => state.title
  },

  reasons: {
    extend: Flex,
    state: 'data',

    childExtend: { extend: Item },
    $stateCollection: ({ state }) => state.parse()
  }
}

const ListItem = {
  props: {
    widthRange: '100%',
    title: {
      fontWeight: '400',
      ':before': { content: '"・"' },
      '@light': { color: 'grey25' },
      '@dark': { color: 'white .65' }
    }
  },
  title: { extend: Pseudo }
}

export const ArticleList = {
  extend: ArticleHighlights,

  props: {
    title: {
      fontSize: 'E2',
      fontWeight: '700',
      margin: '0 0 A'
    },
    reasons: {
      maxWidth: '100%',
      flow: 'column',
      gap: 'A1'
    }
  },

  title: {
    text: ({ state }) => state.title
  },

  reasons: {
    childExtend: ListItem,
    $stateCollection: ({ state }) => state.parse()
  }
}
