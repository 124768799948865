'use strict'

import SALOME_JPG from '../assets/recruiters/Salome_Bakuradze.jpg'
import TEONA_JPG from '../assets/recruiters/Teona_Tchigladze.jpg'
import MARIAM_JPG from '../assets/recruiters/Mariam_Shengelia.jpg'
import MARIAM_S_JPG from '../assets/recruiters/Mariam_Sumbadze.jpg'
import NEKO_JPG from '../assets/recruiters/neko.jpg'
import ANI_JPG from '../assets/recruiters/Ani_Taturashvili.jpg'
import NINI_JPG from '../assets/recruiters/Nini_Chachkhalia.jpg'

export const about = {
  title: 'About',
  description: 'We help businesses recruit faster, smarter and cost effectively',

  mapLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.4024371423825!2d44.75463571620091!3d41.71183408378027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404473f3c18751a1%3A0x112dc69e42a63ec0!2sTerminal%20Towers!5e0!3m2!1sen!2sge!4v1666875412484!5m2!1sen!2sge',

  whoWeAre: 'Who are we and what makes us stand out',

  data: [{
    title: 'Efficiency',
    description: [
      'We are a team of inspired professionals who bring on board experience and expertise in tech recruitment supported by a data-driven approach and accelerated processes and results.',
      'As well-played team members who have worked closely together for years internationally and eventually formed a reliable partner with a mission to become problem-solvers for our partners.'
    ]
  }, {
    title: 'Unique approach',
    description: [
      'We, as a team believe that every human resource challenge for each partner is unique, and we implement individualized solutions based on the companies’ challenges and needs. Close communication with our clients and a thorough understanding of the tech recruitment processes lead us to build bridges between candidates and companies for valuable hiring.'
    ]
  }, {
    title: 'Changing perspective about Agency Hiring',
    description: [
      'We are changing the clients’ perspective towards agency recruitment and inspiring the process to go effectively. Being on the market for three years now, 90% of our partners come through referrals from loyal clients as we together achieve impressive results. '
    ]
  }, {
    title: 'Let’s Talk Numbers',
    description: [
      'We proudly have more than 40 Local and international loyal partners and more than 100 successfully filled positions.'
    ]
  }, {
    title: 'What makes us stand out',
    description: [
      'We strive to make recruitment easy and fast for our partner companies in the most competitive tech environment. As an international IT Recruitment team who provides access from Georgia to Region and the World we believe in the borderless world where expertise meets expectations. We aim to be the biggest Tech Recruitment Agency in the Region.'
    ]
  }],

  recruiters: {
    title: 'Meet our Recruiters',
    data: [{
      name: 'Salome Bakuradze',
      poster: SALOME_JPG,
      linkedin: 'https://www.linkedin.com/in/salome-bakuradze/',
      role: 'Technical Recruiter'
    }, {
      name: 'Teona Tchigladze',
      poster: TEONA_JPG,
      linkedin: 'https://www.linkedin.com/in/teona-tchigladze-449882158/',
      role: 'Data Driven Recruitment Planner'
    }, {
      name: 'Mariam Shengelia',
      poster: MARIAM_JPG,
      linkedin: 'https://www.linkedin.com/in/mariam-shengelia-44a474143/',
      role: 'IT Recruiter'
    }, {
      name: 'Mariami Sumbadze',
      poster: MARIAM_S_JPG,
      linkedin: 'https://www.linkedin.com/in/mariami-sumbadze-971b315a/',
      role: 'Tech Enthusiast'
    }, {
      name: 'Neko Bakuradze',
      poster: NEKO_JPG,
      linkedin: 'https://www.linkedin.com/in/neko-bakuradze-86b401157/',
      role: 'IT Recruiter'
    }, {
      name: 'Ani Taturashvili',
      poster: ANI_JPG,
      linkedin: 'https://www.linkedin.com/in/ani-taturashvili-4b393a220/',
      role: 'Recruiter'
    }, {
      name: 'Nini Chachkhalia',
      poster: NINI_JPG,
      linkedin: 'https://www.linkedin.com/in/nini-chachkhalia/',
      role: 'Senior Tech Sourcer'
    }]
  }
}
