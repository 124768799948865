'use strict'

export const refer_a_friend = { // eslint-disable-line camelcase
  title: 'Refer a Friend',
  description: [
    'We believe that the best candidates come from people we know and trust. Interested in details?'
  ]
}

export const refer_a_client = { // eslint-disable-line camelcase
  title: 'Refer a Client',
  description: [
    'We believe that the best candidates come from people we know and trust. Interested in details?'
  ]
}
