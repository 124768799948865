'use strict'

export const rpo = {
  title: 'RPO',
  description: 'RPO in our language means Perfectly Effortless Hire, While Partnering with RPO Model we go all in with you, become your team members with just one click!',

  what_is_rpo: {
    title: 'What is RPO?',
    description: [
      'You end up in our ATS where you receive access to the pre-selected and handpicked candidates together with the recruitment team of 3 Recruitment Process Owner, Senior Recruiter and Sourcer who makes sure that you find your experience, knowledge and culturally fit candidates with us.'
    ]
  },

  belief: {
    title: 'We Believe that building long term partnerships is the key to success!',
    description: [
      'So Speaking About RPO (Recruitment Process Outsourcing) all the keywords of “Making Hiring Tech Talent Easier” Comes up, such as:'
    ],
    data: [{
      title: 'Reduced costs of direct advertising'
    }, {
      title: 'Short and effective selection process'
    }, {
      title: 'Fast time to Hire'
    }, {
      title: 'Diverse workforce'
    }, {
      title: 'Equitable hiring strategy and an inclusive culture'
    }, {
      title: 'Stronger Quality of hire'
    }, {
      title: 'and many more'
    }]
  },

  highlights_why_choose: {
    title: 'Why to Choose RPO?',

    data: [{
      title: 'While choosing RPO your headache of sourcing, screening, selecting and salary negotiations disappears as our experienced team has it all covered.'
    }, {
      title: 'We Build your talent pool for niche and in-demand skilled candidates for future hiring;'
    }, {
      title: 'We are your RPO team with deep understanding of your companys’ culture which brings Stronger Quality of hires and contributes to your everyday business development process;'
    }, {
      title: 'We sign the deal and the process starts from day one with matching your job descriptions to the industry level offers, sourcing the candidates, holding pre-selection interviews and assigning them to tech interviews for you.'
    }, {
      title: 'The process of on-boarding our recruiters takes no time and effort.'
    }, {
      title: 'All with fixed cost monthly pricing,without any additional recruiter benefit  enrollment and professional training costs - we have that already covered!'
    }]
  },

  goal: {
    title: 'What is the goal of RPO with us?',
    description: [
      'Our goal is to make RPO partnership so accessible and affordable for the companies that it actually costs less than hiring onboard or freelance Tech recruiters/headhunters, however it provides you with enterprise level support and quality as the whole team backs up the strong quality assurance and are focused on building long lasting partnership with you.'
    ]
  },

  highlights_rpo: {
    title: 'When is RPO Good for you?',

    data: [{
      title: 'Do you want to focus on building your company instead of handling all the hiring chatter?'
    }, {
      title: 'Have you decided that you have reached the level of the development where your team is just not big enough for your upcoming projects and aims?'
    }, {
      title: 'Are you receiving new exciting project orders or starting your new product development?'
    }, {
      title: 'Have you decided that you want to enter fresh markets with an unexplored talent pool?'
    }, {
      title: 'Do you have an idea how many developers you would like to hire during the next couple of months?'
    }, {
      title: 'Do you need a certain amount of hires within a very short, and very strict timeline are your clients on your back?'
    }]
  },

  headline: ['Then RPO is the Best Solution for you as it comes at least 50% Cheaper and faster than traditional Hiring! And it’s easy!']
}
