'use strict'

import { Button, Flex } from 'smbls'

export const TooltipParent = {
  props: {
    position: 'relative',
    zIndex: 999,
    style: {
      '&:hover, &:focus-visible': {
        zIndex: 1000,

        '& [tooltip]': {
          transform: 'translate3d(-50%,0,0)',
          opacity: 1,
          visibility: 'visible'
        }
      }
    }
  }
}

export const DropdownParent = {
  props: {
    position: 'relative',
    zIndex: 999,
    style: {
      '&:hover': {
        zIndex: 1000,
        '> section': {
          transform: 'translate3d(0,0,0)',
          opacity: 1,
          visibility: 'visible'
        }
      }
    }
  }
}

export const Dropdown = {
  props: {
    background: 'dropdownDark .85',
    color: 'white',
    round: 'Z2',

    position: 'absolute',
    top: 'B2',
    left: '0',
    transform: 'translate3d(0, -10px, 1px)',
    minWidth: 'F',
    maxHeight: 'H',
    transition: 'C',
    transitionProperty: 'visibility, transform, opacity',
    opacity: '0',
    overflow: 'hidden auto',
    visibility: 'hidden',
    boxSizing: 'border-box',
    cursor: 'default',
    zIndex: 1000,

    style: {
      backdropFilter: 'blur(3px)'
    },

    '@dark': { boxShadow: 'black .65, 0 10px 50px -10px' },
    '@light': { boxShadow: 'gray10, 0 10px 50px -10px' }
  },

  tag: 'section'
}

const DropdownItem = {
  props: {
    boxShadow: 'none',
    round: '0',
    background: 'transparent 0',
    opacity: 0.75,
    transition: 'A',
    transitionProperty: 'visibility, color, background, opacity',
    fontWeight: '400',

    '@dark': {
      color: 'white'
    },
    '@light': {
      background: 'gray12',
      color: 'black'
    },

    ':hover': {
      opacity: 1,
      '@dark': { background: 'white .4' },
      '@light': { background: 'white .5' }
    },

    '.isActive': {
      fontWeight: '500',
      opacity: 1,
      '@dark': { background: 'white .15' },
      '@light': { background: 'white' },

      style: {
        svg: { opacity: 0.5 }
      },
      ':hover': {
        style: {
          svg: { opacity: 0.5 }
        },
        '@dark': { background: 'gray5 .15' },
        '@light': { background: 'white' }
      }
    }
  }
}

const ListItem = {
  extend: [DropdownItem, Button],
  props: {
    align: 'center flex-start',
    padding: 'Z2 A2',
    textAlign: 'start',
    text: 'List Item',
    widthRange: '100%',
    fontSize: 'Z1',

    caption: {
      marginLeft: 'Z2',
      textTransform: 'capitalize',
      opacity: '.65',
      whiteSpace: 'nowrap'
    }
  },
  caption: {}
}

export const ListInDropdown = {
  extend: Flex,
  tag: 'nav',
  props: {
    flow: 'column',
    align: 'stretch flex-start'
  },
  childExtend: ListItem
}

export default Dropdown
