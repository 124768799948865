'use strict'

import { Article } from '../../components'

export const RecruitingPartner = {
  extend: Article,

  state: 'recruiting_partner',

  header: {},

  content: {
    ArticleHeading: {},

    ArticleParagraphs_paragraphs: {
      state: 'paragraphs'
    },

    ArticleHeadline: {
      state: 'headline',
      $stateCollection: ({ state }) => state.parse()
    },

    ArticleSection_our_talent: {
      state: 'our_talent'
    },

    ArticleSection_cooperation: {
      state: 'cooperation'
    },

    ArticleSection_recruit_hire: {
      state: 'recruit_hire'
    },

    ArticleHeadline_headline_source: {
      state: 'headline_source',
      $stateCollection: ({ state }) => state.parse()
    },

    ArticleList_highlights: {
      state: 'highlights'
    }
  },

  ArticleFooter: {},
  OtherServices: {},
  FooterNav: {}
}
