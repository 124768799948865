'use strict'

import { Flex } from 'smbls'
import { NavLink } from './Link'

export const FooterNav = {
  extend: Flex,

  props: {
    padding: 'D2 C A B2',
    justifyContent: 'space-between',

    '@mobileL': {
      margin: '0',
      align: 'center center',
      flexDirection: 'column-reverse',
      gap: 'A',
      padding: 'B1 A'
    }
  },

  copyright: {
    extend: Flex,
    props: { gap: 'A' },

    textOne: {
      props: {
        margin: '0',
        opacity: '0.65',
        fontWeight: '600'
      },
      text: '4IT'
    },
    textTwo: {
      props: {
        margin: '0',
        opacity: '0.35'
      },
      text: 'Copyright © 2019-2022'
    }
  },

  menu: {
    extend: Flex,

    props: {
      margin: '0',
      padding: '0',
      gap: 'B',
      opacity: '0.65',
      style: { listStyle: 'none' }
    },

    childExtend: NavLink,

    ...[
      { text: 'About us', href: '/about' },
      { text: 'Hiring', href: '/hiring' },
      { text: 'Contact', href: '/contact' }
    ].map(props => ({ props }))
  }
}
