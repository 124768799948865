'use strict'

export const market_research = { // eslint-disable-line camelcase
  title: 'Market Research',
  description: 'Have you decided to enter a new market and access a fresh talent pool?',

  data: [{
    title: 'Do you want to know the tech stack availability in Georgia and Neighboring countries?'
  }, {
    title: 'Are you tired of researching the salary rates and benefit packages offered for tech candidates? '
  }, {
    title: 'Do you want to know how taxation works in Georgia and the possible Models of collaboration between company and talent?'
  }, {
    title: 'Do you want to know the professional level of English in the region?'
  }, {
    title: 'What factors do tech talent usually consider when deciding whether to accept a job offer?'
  }, {
    title: 'What benefits are usually offered by companies in Georgia?'
  }, {
    title: 'Are you keen on understanding the cultural specifications you will need better to build your onsite or remote teams in our region?'
  }],

  market: {
    title: 'Whether you are looking at market expansion or new talent acquisition',
    description: ['Lets Talk!']
  }
}
