'use strict'

var defFont = { // eslint-disable-line
  name: '',
  url: '',
  fontStyle: '',
  fontWeight: 500,
  fontStretch: 'normal',
  fontOpticalSizing: 'auto'
}

export const FONT = {}
