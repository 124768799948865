'use strict'

export const recruiting_partner = { // eslint-disable-line camelcase
  title: 'Recruiting Partner',
  description: 'Recruitment Partner Service is an embedded solution for companies who are looking for fast results with long term value.',

  paragraphs: [
    'Our recruiters take no time to onboard and work for you as your own team members, remotely or from your office. We manage candidates from the beginning of the hiring process through the end, partnering with you until the hires are completed.'
  ],

  headline: ['On average, a start-up saves up to 46% on costs with embedded Recruitment Service!'],

  our_talent: {
    title: 'Our Talent, Your Team',
    description: [
      '4IT puts experienced recruiters on your team, to understand your culture, advise on strategy, and provide exclusive sourcing and hiring support for your most critical roles. Our team comes fully trained in using data and intelligence to help you scale your team, hire great people, and understand new markets. We integrate with your internal team to give you the support you need, adding value along the way.'
    ]
  },

  cooperation: {
    title: 'Cooperation, Not Competition',
    description: [
      'Sometimes the traditional recruitment approach increases competition in the market for top talent, especially when companies are partnering with different recruitment companies in the same market. Embedded recruiting eliminates that contrast while building a partnership relationship between recruiter, client, and candidate.'
    ]
  },

  recruit_hire: {
    title: 'We Recruit You Hire!',
    description: [
      'Your Recruitment partner builds sustainable systems and processes, we bring on board years of experience while filling your roles, building internal capabilities for sustainable success that continues long after our engagement concludes.'
    ]
  },

  headline_source: ['We Source, Interview, offer and Hire for you!  We come on board with a database with direct access to 10 000 Professionals, Experienced Recruiters, Ready Tools and ATS (Application Tracking System) to be used to build your dream team!'],

  highlights: {
    title: 'Recruitment Partner Service is best used when:',

    data: [{
      title: 'You have just decided to enter new markets and business locations;'
    }, {
      title: 'There is a lack of recruitment Capacity in your team;'
    }, {
      title: 'You are behind on your hiring needs;'
    }, {
      title: 'You have a new project and decided to grow your team;'
    }, {
      title: 'You are having trouble accessing the passive candidates'
    }]
  }
}
