'use strict'

export const recruiting = {
  title: 'Recruiting',
  description: 'The smart work of our headhunters, sourcers and recruiters has benefited establishing trust and sustainability',

  paragraphs: [
    'Discovering and attracting excellent employees is never simple. Tech recruitment, especially, represents a challenging task during ever growing demand on skilled tech talent around the world. If your goal is to score the best software engineer first, you have to know what is the right place to look for them.',
    'Recruitment and Headhunting at 4IT is the  process of sourcing, attracting, and hiring technical talent to fulfill your business needs.',
    'Our IT recruitment service is a guarantee to gain access to the ready pool of pre-qualified candidates to meet your requirements, significantly reducing time to fill metrics and the right source to find highly skilled and experienced professionals to suit your unique hiring requirements.'
  ],

  highlights: {
    title: 'Why to Choose Tech Recruitment?',

    data: [{
      title: 'Saving time',
      description: 'Access to our talent pool of candidates'
    }, {
      title: 'Quality over quantity',
      description: 'Exactly matching the skills and experience you are looking for.'
    }, {
      title: 'No commission',
      description: 'No commission until successful hiring'
    }, {
      title: 'A replacement guarantee',
      description: 'which means that we hire a substitute candidate at its own expense.'
    }, {
      title: 'Reducing Advertising',
      description: 'Reducing the Need for Direct Advertising, Advertising is expensive.'
    }]
  },

  goal: {
    title: 'What is the goal of Recruitment and Headhunting with us?',
    description: [
      'Recruitment and Headhunting at 4IT is the  process of sourcing, attracting, and hiring technical talent to fulfill your business needs.'
    ]
  }
}
