'use strict'

import { Flex } from 'smbls'

const props = {
  height: '100vh',
  padding: 'B 0',
  flow: 'column',
  align: 'center space-between',
  position: 'sticky',
  top: '0',
  minWidth: 'E1',
  fontSize: 'X2',

  '@mobileL': {
    position: 'relative',
    width: '100%',
    flow: 'row',
    height: 'auto',
    align: 'center space-between',
    borderRight: 'none',
    padding: 'A A2'
  },

  '@dark': { borderRight: '1px, solid, #111b1a' },
  '@light': { borderRight: '1px, solid, #E6EFEE' }
}

export const Navbar = {
  extend: Flex,
  props,

  Logo: {
    margin: 'Y2 0 0',
    height: 'C',
    '@mobileL': {
      margin: '0',
      height: 'B1'
    }
  },
  Link: {
    text: 'Navigation',
    href: '/navigation',
    fontSize: 'A2',
    fontWeight: '700',
    lineHeight: '1',
    textTransform: 'uppercase',
    transform: 'rotate(180deg)',
    style: {
      writingMode: 'vertical-rl'
    },
    '@mobileL': {
      margin: '0',
      transform: 'rotate(0deg)',
      style: {
        writingMode: 'unset'
      }
    }
  },
  Pills: {
    '@mobileL': {
      display: 'none'
    }
  }
}
