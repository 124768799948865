'use strict'

import { Main } from './Main'
import { Navigation } from './Navigation'
import { Recruiting } from './Recruiting'
import { RPO } from './RPO'
import { RecruitingPartner } from './RecruitingPartner'
import { Hiring } from './Hiring'
import { MarketResearch } from './MarketResearch'
import { About } from './About'
import { Contact } from './Contact'
import { ReferAFriend, ReferAClient } from './Refer'

export default {
  '/': Main,
  '/navigation': Navigation,
  '/recruiting': Recruiting,
  '/rpo': RPO,
  '/recruiting-partner': RecruitingPartner,
  '/hiring': Hiring,
  '/market-research': MarketResearch,
  '/about': About,
  '/refer-a-friend': ReferAFriend,
  '/refer-a-client': ReferAClient,
  '/contact': Contact
}
