'use strict'

import { Flex, Link } from 'smbls'

const ServiceColumn = {
  extend: Link,

  props: ({ state }) => ({
    href: state.href,
    padding: 'X B2 X B1',
    margin: '0 0 C3',
    position: 'relative',

    '@mobileL': { margin: '0 0 A' },

    '@light': { color: 'black' },
    '@dark': { color: 'white' },

    heading: {
      color: state.color || 'pink',
      fontSize: 'I1',
      margin: '0'
    },
    paragraph: {
      margin: '0',
      fontWeight: '400'
    },
    shadow: {
      position: 'absolute',
      inset: '0 0 0 0',
      opacity: 1,
      transition: 'opacity D defaultBezier',
      background: 'bottomFadingShadow'
    },
    ':hover': {
      style: {
        span: { opacity: 0 }
      }
    }
  }),
  heading: {
    tag: 'h1',
    text: ({ state }) => state.title
  },
  paragraph: {
    tag: 'p',
    text: ({ state }) => state.description
  },
  shadow: { tag: 'span' }
}

const DATA = [{
  title: 'Recruiting',
  href: '/recruiting',
  color: 'blue',
  description: 'You end up in our ATS where you receive access to the pre-selected and handpicked candidates together with the recruitment team of 3 Recruitment Process Owner, Senior Recruiter and Sourcer who makes sure that you find your experience, knowledge and culturally fit candidates with us.'
}, {
  title: 'RPO',
  href: '/rpo',
  color: 'indigo',
  description: 'You end up in our ATS where you receive access to the pre-selected and handpicked candidates together with the recruitment team of 3 Recruitment Process Owner, Senior Recruiter and Sourcer who makes sure that you find your experience, knowledge and culturally fit candidates with us.'
}, {
  title: 'Recruiting Partner',
  href: '/recruiting-partner',
  color: 'pink',
  description: 'Recruitment Partner Service is an embedded solution for companies who are looking for fast results with long term value. Our recruiters take no time to onboard and work for you as your own team members, remotely or from your office. We manage candidates from the beginning of the hiring process through the end, partnering with you until the hires are completed.'
}]

export const OtherServices = {
  props: {
    padding: 'D2 D2 0',
    '@mobileL': { padding: 'C3 0 0' }
  },

  label: {
    tag: 'p',
    text: 'Check also:',

    props: {
      fontWeight: '500',
      margin: '0 0 X2 B1',
      lineHeight: '1.563',

      '@mobileL': { margin: '0 0 A B1' },

      '@light': { color: 'grey25' },
      '@dark': { color: 'white .5' }
    }
  },

  services: {
    extend: Flex,
    props: {
      gap: 'C',
      maxWidth: 'J',
      '@mobileL': { gap: '0', flow: 'column' }
    },

    childExtend: ServiceColumn,
    $stateCollection: () => DATA.filter(data => data.href !== window.location.pathname)
  }
}
