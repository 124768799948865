'use strict'

export const contact = {
  title: 'Contact',
  description: 'Get Familiar with 4IT Services and get your hiring process easier!',

  mapLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.4024371423825!2d44.75463571620091!3d41.71183408378027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404473f3c18751a1%3A0x112dc69e42a63ec0!2sTerminal%20Towers!5e0!3m2!1sen!2sge!4v1666875412484!5m2!1sen!2sge',

  data: [{
    title: 'Terminal ∙ ტერმინალი',
    description: '36, 34, 0179 Irakli Abashidze Street, Tbilisi'
  }, {
    title: 'recruit@4it.ge',
    href: 'mailto:recruit@4it.ge',
    description: 'Contact us via email'
  }, {
    title: 'Linkedin',
    href: 'https://www.linkedin.com/company/4it-recruit-georgia/mycompany/',
    description: 'Contact us via Linkedin'
  }]
}
