'use strict'

import { Link } from 'smbls'

export const NavLink = {
  extend: Link,
  props: ({ props, context }) => {
    const { location: { pathname } } = window

    return {
      display: 'block',
      active: pathname === props.href,
      transition: 'A defaultBezier',
      transitionProperty: 'text-shadow',
      padding: 'X2 Z2',
      fontWeight: '500',

      '.active': {
        opacity: '1',
        textShadow: '.65px, .65px, 0px, white'
      },
      '!active': {
        opacity: '0.85',
        textShadow: 'none',

        ':hover': {
          opacity: '.9',
          textShadow: '.5px, .5px, 0px, white'
        }
      }
    }
  }
}

export { Link }
