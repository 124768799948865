'use strict'

import { Flex, Link } from 'smbls'

export const Pills = {
  extend: Flex,

  props: {
    padding: '0',
    flexDirection: 'column',
    align: 'flex-end flex-end',
    gap: 'Z2',
    height: 'D2',
    style: { listStyle: 'none' }
  },

  childExtend: {
    extend: Link,

    props: ({ props }) => ({
      isActive: props.href === window.location.pathname,
      round: 'A',
      width: 'Z',
      height: 'Z',
      background: 'blue .15',
      transition: 'C defaultBezier',
      transitionProperty: 'height, opacity',

      '.isActive': {
        background: 'blue .35',
        width: 'Z',
        height: 'C'
      }
    })
  },

  shapeOne: {
    props: { href: '/market-research' }
  },
  shapeTwo: {
    props: { href: '/recruiting' }
  },
  shapeThree: {
    props: { href: '/rpo' }
  }
}
