'use strict'

const Form = {
  Flex: {
    FriendsName: {
      required: true,
      type: 'text'
    },
    FriendsEmail: {
      required: true,
      type: 'email'
    },
    FriendsPhone: {
      required: true,
      type: 'phone'
    },
    FriendsPortfolio: {
      required: true,
      type: 'url'
    },

    Resume: {
      required: true,
      type: 'file'
    },

    YourName: {
      required: true,
      type: 'text'
    },
    YourEmail: {
      required: true,
      type: 'email'
    }
  }
}

export default {
  state: 'refer_a_friend',
  content: { 
    ArticleHeading: {},
    Form
  }
}
