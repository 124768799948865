'use strict'

import { Article } from '../../components'

export const MarketResearch = {
  extend: Article,

  state: 'market_research',

  header: {},

  content: {
    props: { minHeight: '65vh' },

    ArticleHeading: {},

    ArticleList: {
      title: null,
      props: { margin: '-B 0 0' }
    },

    ArticleSection: {
      state: 'market'
    }
  },

  ArticleFooter: {},
  FooterNav: {}
}
