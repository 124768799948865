'use strict'

import { Button, Link } from 'smbls'

export const ButtonCTA = {
  extend: [Link, Button],

  props: {
    href: 'https://calendly.com/mariam-sum/lets-talk-4it-4-you',
    target: '_blank',
    fontSize: 'A',
    gap: 'Z',
    height: 'C',
    fontWeight: '500',
    round: 'B2',
    text: 'Book a Call',
    padding: '0.49em B 0.47em B',
    theme: 'primary',
    transition: 'background B',

    Icon: { name: 'arrow' }
  }
}

export const ButtonSubmit = {
  extend: Button,

  props: {
    fontSize: 'A',
    gap: 'Z',
    height: 'C',
    fontWeight: '500',
    round: 'B2',
    text: 'Book a Call',
    padding: '0.49em B 0.47em B',
    theme: 'primary',
    transition: 'background B',

    Icon: { name: 'arrow' }
  }
}
