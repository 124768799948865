'use strict'

export const SPACING = {
  ratio: 1.618,
  subSequence: true
  // '@tabletS': {
  //   ratio: 1.45
  // },
  // '@mobileM': {
  //   ratio: 1.2
  // }
}
