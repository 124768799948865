'use strict'

const Form = {
  Flex: {
    ClientsName: {
      required: true,
      type: 'text'
    },
    ClientsEmail: {
      required: true,
      type: 'email'
    },

    YourName: {
      required: true,
      type: 'text'
    },
    YourEmail: {
      required: true,
      type: 'email'
    }
  }
}

export default {
  state: 'refer_a_client',
  content: { 
    ArticleHeading: {},
    Form
  }
}
