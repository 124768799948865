'use strict'

export const navigation = [{
  title: 'Recruiting',
  data: [{
    title: 'Recruiting',
    href: '/recruiting',
    description: 'We deliver great talent at speed, go the extra mile to ensure candidates are the perfect fit for you and find highly skilled professionals to suit your companys’ unique hiring needs.'
  }, {
    title: 'RPO',
    href: '/rpo',
    description: 'RPO in our language means Perfectly Effortless Hire, While Partnering with RPO Model we go all in with you, become your team members with just one click!'
  }, {
    title: 'Recruiting Partner',
    href: '/recruiting-partner',
    description: 'Your recruiter will take no time to onboard and work for you as your own team member, remotely or from your office with a database access to 10 000+ professionals, ready tools and ATS (Application Tracking System) to be used to build your dream team!'
  }]
}, {
  title: 'Market Overview',
  data: [{
    title: 'Georgian Market Research',
    href: '/market-research',
    description: 'Have you decided to enter a new market and access a fresh talent pool?'
  }]
}, {
  title: 'Conculting',
  data: [{
    title: 'Book a Call',
    href: 'https://calendly.com/mariam-sum/lets-talk-4it-4-you',
    description: 'Whether you are looking at market expansion or new talent acquisition let\'s talk'
  }, {}, {
    title: 'About us',
    href: '/about',
    description: 'We are a team of professionals who bring on board experience and expertise in tech recruitment supported by a data…'
  }]
}, {
  title: 'Job Searching',
  data: [{
  //   title: 'Open Positions',
  //   href: '/hiring',
  //   description: ' '
  // }, {}, {
    title: 'Refer a Friend',
    href: '/refer-a-friend',
    description: 'We believe that the best candidates come from people we know and trust. Interested in details?'
  }]
}]
