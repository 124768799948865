'use strict'

import IVERI_JPG from '../assets/iveri.jpg'
import GIA_JPG from '../assets/gia.jpg'
import MARIAM_JPG from '../assets/mariam.jpg'

export const client_testimonials = [{ // eslint-disable-line camelcase
  name: 'Iveri Gholijashvili',
  role: 'Head of Mobile Department at Leavingstone',
  poster: IVERI_JPG,
  linkedin: 'https://www.linkedin.com/in/iveri-gholijashvili7/',
  description: '4IT team provides valuable expertise in recruitment and hiring process. We look to them as our trusted advisor as they do their best to understand the business needs and try to find the perfect match whether technically or behaviorally. They excel in sourcing and head-hunting. They also value the customer\'s relationship with their clients, and that is reflected in the professionalism of their interactions. The bottom line is that they make sourcing and head-hunting easier and help in getting to the right candidates faster.'
}, {
  name: 'Gia Metreveli',
  role: 'Talent lead at Gorillas',
  poster: GIA_JPG,
  linkedin: 'https://www.linkedin.com/in/gia-metreveli-418a42b5/',
  description: '4IT has done a spectacular job while helping us scale with their RPO/embedded recruiting model. We have tried a few solutions and search agencies before, though, the approach and execution from 4IT were at the next level. They were available on short notice and the immediacy of the impact was very vivid. They were not only looking for a matching technical profile but also for people who fit us and our values. We have had a great solo recruiter sent from 4IT, who felt just like an internal team member, who was very resourceful and had major support from their back office. Ultimately, we have had a fun & successful ride with the 4IT team and ended up spending way less than we would have expected with regular agencies.'
}, {
  name: 'Mariam Mgeladze',
  role: 'HR Manager at Flat Rock Technology',
  poster: MARIAM_JPG,
  linkedin: 'https://www.linkedin.com/in/mariammgeladze95/',
  description: '4IT is the best recruitment agency I\'ve had the privilege of working with. IT is a very competitive market and each role the recruiters are looking to fill is different and unique but they always provide exceptional candidates in a timely manner.They are dedicated, responsive and keep updating about the process. They are able to understand the needs of our organization and what we are looking for in terms of candidates and future employees. They build trustworthy relationships with the candidates and understand their strengths and weaknesses in order to correctly identify possible roles for them within the companies which they represent.'
}]

export const candidate_testimonials = [{ // eslint-disable-line camelcase
  name: 'Mariam Dolidze',
  role: 'Software Quality Assurance Manager and Product Owner',
  description: 'First of all thank you very much for taking an interest in me and writing to me about this opportunity. I got confirmation from the company and a pretty good offer and we are going to collaborate and it would not be possible without your company. I wanted to write that You are really one of the best and this is not the first time I have received information from you about different vacancies, you always cooperate with amazingly cool companies.Thank you very much again for this opportunity and for what you are doing.'
}]
