'use strict'

import { Flex } from 'smbls'
import { TestimonialCard } from './'

export const Testimonials = {
  extend: Flex,
  tag: 'section',

  state: 'client_testimonials',

  props: {
    gap: 'A1',
    align: 'flex-start flex-start',
    maxWidth: 'J2',
    overflow: 'auto hidden',
    margin: 'D -C1 B',
    padding: '0 B1',

    '::-webkit-scrollbar': {
      dispay: 'none'
    },

    '@mobileL': {
      margin: '0 -Z2',
      padding: '0',
      flow: 'column',
      gap: 'B1'
    },

    childProps: {
      '@mobileL': { margin: '0' }
    }
  },

  childExtend: TestimonialCard,
  $stateCollection: ({ state }) => state.parse()
}
