'use strict'

import { ButtonCTA } from './'

import MAP_LIGHT_SVG from '../assets/map-light.svg'
import MAP_DARK_SVG from '../assets/map-dark.svg'

import Parallax from 'parallax-js'

export const Map = {
  extend: 'Overflow',

  props: {
    position: 'relative',
    margin: 'B1 -A',
    height: 'H2',
    round: 'B',
    overflow: 'hidden',
    userSelect: 'none',
    '@mobileL': {
      margin: 'B1 -Z2',
      height: 'G'
    }
  },

  mapImg: {
    props: {
      position: 'absolute !important',
      inset: '0 0 0 0'
    },

    attr: { 'data-depth': '1' },

    Picture: {
      extend: 'Picture',

      props: {
        margin: 'A 0 0 0',
        display: 'block',
        minWidth: '100%',
        position: 'absolute',
        inset: '50% 50%',
        src: MAP_DARK_SVG,
        width: 'fit-content',
        height: 'fit-content',
        transform: 'translate3d(-50%, -50%, 1px)'

        // '@mobileL': { widthRange: '100% J' }
      },

      '@dark': { props: { srcset: MAP_DARK_SVG } },
      '@light': { props: { srcset: MAP_LIGHT_SVG } }
    }
  },

  tooltip: {
    attr: { 'data-depth': '0.8' },

    props: {
      shape: 'tooltip',
      shapeDirection: 'bottom',
      background: 'black',
      round: 'Z2',
      position: 'absolute !important',
      fontSize: 'X2',
      padding: 'Z A',
      fontWeight: '600',
      color: 'white',
      text: 'We\'re here',

      style: {
        left: '56% !important',
        top: '49% !important'
      },

      '@mobileL': {
        transform: 'translateZ(99px) !important'
      }
    }
  },

  on: {
    render: ({ node }) => {
      const scene = node
      return new Parallax(scene)
    }
  }
}

export const MapSection = {
  props: {
    maxWidth: 'J2',
    margin: 'E 0 D1'
  },

  h1: {
    text: 'Have you decided to enter a new market and get access to a fresh talent pool?',
    props: {
      margin: '0',
      minWidth: 'G2',
      '@mobileL': {
        fontSize: 'C2',
        minWidth: 'F',
        margin: '-B1 D 0 0'
      }
    }
  },

  p: {
    text: 'Do you want to know what the tech stack availability is in Georgia and neighbouring countries?',
    props: {
      margin: 'Y2 0 0 0',
      color: 'text',
      maxWidth: 'H'
    }
  },

  Map,

  CTA: {
    extend: ButtonCTA,
    props: {
      '@mobileL': { display: 'none' }
    }
  }
}
