'use strict'

import config from '../symbols.json'
import { create } from 'smbls'

import DESIGN_SYSTEM from './designSystem'
import PAGES from './pages'
import * as COMPONENTS from './components'
import * as SNIPPETS from './utils'
import * as STATE from './state'

import { FastClick } from 'fastclick'

const state = {
  globalTheme: 'dark',
  ...STATE
}

create({
  props: {
    theme: 'document',
    width: '100%',
    minHeight: '100%'
  }
}, {
  key: config.key,
  designSystem: DESIGN_SYSTEM,
  state,
  components: COMPONENTS,
  pages: PAGES,
  snippets: SNIPPETS,
  routerOptions: {
    scrollNode: document.body,
    scrollToOptions: { behavior: 'instant' }
  }
})

FastClick.attach(document.body)
