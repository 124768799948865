'use strict'

import { Flex } from 'smbls'
import { Partner } from './'

import GLOVO from '../assets/brands/glovo.png'
import TBC from '../assets/brands/tbc.png'
import SINGULAR from '../assets/brands/singular.png'
import FRT from '../assets/brands/frt.png'
import FRT_DARK from '../assets/brands/frt_dark.png'
import LEAVINGSTONE from '../assets/brands/leavingstone.png'
import SYMBOLS from '../assets/brands/symbols.png'
import SYMBOLS_DARK from '../assets/brands/symbols_dark.png'
import MONITE from '../assets/brands/monite.png'
import MONITE_DARK from '../assets/brands/monite_dark.png'
import GBA from '../assets/brands/gba.png'
import GBA_DARK from '../assets/brands/gba_dark.png'

export const Partners = {
  extend: Flex,
  props: {
    alignSelf: 'center',
    margin: 'D 0 E1',
    gap: 'D',
    align: 'center flex-start',
    fontSize: 'A1',
    '@mobileL': {
      fontSize: 'Z2',
      gap: 'B2',
      margin: 'C2 auto 0',
      align: 'center center',
      flow: 'row wrap'
    }
  },

  childExtend: Partner,

  logo_1: {
    props: {
      src: GLOVO,
      height: '1.75em',
      opacity: '0.35',
      '@mobileL': { height: 'A2' }
    }
  },
  logo_2: {
    props: {
      src: TBC,
      height: '1.8em',
      opacity: '0.40',
      '@mobileL': { height: '1.3em' }
    }
  },
  logo_3: {
    props: {
      src: SINGULAR,
      height: '1.8em',
      opacity: '.5',
      '@mobileL': { height: 'A2' }
    }
  },
  logo_4: {
    props: {
      src: FRT,
      height: '2.35em',
      opacity: '.25',
      '@mobileL': { height: '2em' }
    },
    '@dark': { props: { srcset: FRT_DARK } },
    '@light': { props: { srcset: FRT } }
  },
  logo_5: {
    props: {
      src: LEAVINGSTONE,
      height: '2em',
      opacity: '0.5',
      '@mobileL': { height: '1.75em' }
    }
  },
  logo_6: {
    props: {
      src: SYMBOLS,
      height: '1.75em',
      opacity: '.3',
      '@mobileL': { height: '1.51em' }
    },
    '@dark': { props: { srcset: SYMBOLS_DARK } },
    '@light': { props: { srcset: SYMBOLS } }
  },
  logo_7: {
    props: {
      src: MONITE,
      height: '1.25em',
      opacity: '.35',
      '@mobileL': { height: '1.35em' }
    },
    '@dark': { props: { srcset: MONITE_DARK } },
    '@light': { props: { srcset: MONITE } }
  },
  logo_8: {
    props: {
      src: GBA,
      height: '2.3em',
      opacity: '0.25',
      '@mobileL': { height: '1.5em' }
    },
    '@dark': { props: { srcset: GBA_DARK } },
    '@light': { props: { srcset: GBA } }
  }
}
