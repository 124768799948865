'use strict'

export const hiring = {
  title: 'Open Positions',

  data: [{
    title: 'Flutter Developer',
    description: 'Tbilisi, Georgia',
    href: 'https://careers-page.com/4it-3/job/L8393533'
  }, {
    title: 'Devops Engineer',
    description: 'Tbilisi, Georgia',
    href: 'https://www.careers-page.com/4it-3/job/L5Y9WY65'
  }, {
    title: 'Solutions Architect (React)',
    description: 'Tbilisi, Georgia',
    href: 'https://www.careers-page.com/4it-3/job/L7X8546X'
  }, {
    title: 'Solution Architect (Java)',
    description: 'Tbilisi, Georgia',
    href: 'https://www.careers-page.com/4it-3/job/L4R94V75'
  }, {
    title: 'Senior/Regular Java developer',
    description: 'Tbilisi, Georgia',
    href: 'https://www.careers-page.com/4it-3/job/QW3R86Y9'
  }]
}
