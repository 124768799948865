'use strict'

import { Link } from 'smbls'

export const Logo = {
  extend: [Link],

  props: {
    href: '/',
    textDecoration: 'none'
  },

  Svg: ({ context }) => ({
    src: context.designSystem.SVG_DATA && context.designSystem.SVG_DATA.logo,
    boxSize: 'C2',
    alignSelf: 'center'
  })
}
