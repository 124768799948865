'use strict'

import { Flex, Clickable } from 'smbls'
import { ButtonCTA } from '.'

export const Hero = {
  extend: Flex,

  props: {
    position: 'relative',
    flow: 'column',
    align: 'flex-start center',
    maxWidth: 'I1-default',
    minHeight: '65vh',
    padding: '- C1 - D1',

    '@tabletS': {
      padding: '- - - B'
    },

    '@mobileL': {
      padding: '- - - C'
    },

    '@mobileS': {
      padding: '- - - A'
    }
  },

  heading: ({ state }) => ({
    H1: {
      fontSize: 'K',
      maxWidth: 'G',
      lineHeight: '1.2',
      margin: '0 0 X2',
      text: state.title || 'We recruit faster, smarter and cost effectively',
      '@mobileL': {
        fontSize: 'F',
        margin: '0'
      }
    },

    P: {
      color: 'text',
      margin: 'B 0 0 0',
      lineHeight: '1.5',
      maxWidth: 'H2-default',
      text: state.description || 'That\'s why 90% of our clients are our happy returning customers and the new partners who come with us through the referrals and stay for long.',
      '@mobileL': {
        fontSize: 'Z',
        margin: 'Z1 0 0 0'
      }
    }
  }),

  btn: {
    extend: [ButtonCTA, Clickable],
    props: {
      theme: 'branded',
      margin: 'B2 -X2 -X2'
    }
  }
}
