'use strict'

import { Flex, Img, Link } from 'smbls'

export const TestimonialCard = {
  extend: Flex,

  props: {
    flow: 'column',
    gap: 'A',
    widthRange: 'H H2',
    flex: 1,

    '@mobileL': {
      fontSize: 'A',
      widthRange: '100%'
    }
  },

  feedback: {
    tag: 'p',

    props: ({ state }) => ({
      active: state.expanded,
      round: 'B',
      opacity: '.65',
      margin: '0',
      padding: 'A A A A1',
      border: 'blue .2, solid, 2px',
      borderRadius: 'B',
      position: 'relative',
      overflow: 'hidden',
      userSelect: 'none',
      cursor: 'pointer',
      height: 'F',
      transition: 'B default-bezier',
      transitionProperty: 'height, opacity',

      '@light': { background: 'white' },
      '@dark': { background: 'gray8' },

      ...(state.expanded && {
        height: 'auto',
        opacity: '.9'
      }),

      '@mobileL': {
        padding: 'A A C A1'
      },
      ':hover': {
        style: { span: { transform: 'translate3d(0, 20px, 0)' } }
      }
    }),

    text: ({ state }) => state.description,
    shadow: {
      tag: 'span',
      props: ({ state }) => ({
        position: 'absolute',
        bottom: '0',
        left: '0',
        boxSize: '35% 100%',
        transition: 'transform C',
        transform: state.expanded ? 'translate3d(0, 100px, 0)' : 'translate3d(0, 0, 0)',
        background: 'bottomFadingShadow'
      })
    },
    on: {
      click: (ev, el, s) => {
        s.update({ expanded: !s.expanded })
      }
    }
  },

  author: {
    extend: [Link, Flex],
    props: ({ state }) => ({
      href: state.linkedin,
      target: '_blank',
      padding: '0 A1',

      align: 'center flex-start',
      gap: 'Z',

      ':hover': {
        style: {
          h6: { textDecoration: 'underline' }
        }
      },

      '@mobileL': {
        margin: '-X 0 0',
        minWidth: '100%',
        flexDirection: 'column',
        align: 'center center'
      }
    }),

    avatar: {
      extend: Img,
      if: ({ state }) => state.poster,
      props: ({ state }) => ({
        src: state.poster,
        widthRange: 'C C',
        aspectRatio: '1 / 1',
        padding: '0',
        round: 'C1',
        '@mobileL': {
          position: 'relative',
          top: '-B2',
          minWidth: 'C',
          maxHeight: 'C'
        }
      })
    },

    title: {
      props: {
        padding: '0',
        margin: '0',
        lineHeight: '1.3',
        '@mobileL': {
          margin: '-B1 0 0 0',
          textAlign: 'center'
        },
        name: {
          fontWeight: '600',
          fontSize: 'A',
          margin: '0',
          '@mobileL': {
          }
        },
        position: {
          fontSize: 'Z1',
          fontWeight: '500',
          opacity: '.65',
          '@mobileL': {
            fontSize: 'Z'
          }
        }
      },

      name: {
        tag: 'h6',
        text: ({ state }) => state.name
      },
      position: {
        text: ({ state }) => state.role
      }
    }
  }
}
