'use strict'

export const main = {
  title: 'We recruit faster, smarter and cost effectively',
  description: 'That\'s why 90% of our clients are our happy returning customers and the new partners who come with us through the referrals and stay for long.',

  value_proposition: {
    title: 'Whether you are looking at market expansion or new talent acquisition',

    description: [
      'Do you want to know the professional level of English in the region?',
      'What factors do tech talent usually take into consideration when deciding whether to accept a job offer?',
      'What factors do tech talent usually take into consideration when deciding whether to accept a job offer?'
    ]
  }
}
