'use strict'

import { setCookie } from '@domql/utils'
import { Flex, init, SquareButton } from 'smbls'
import { ButtonCTA } from './Buttons'
import { DropdownParent } from './Dropdown'
import { NavLink } from './Link'

export const Header = {
  extend: Flex,

  props: {
    align: 'center space-between',
    margin: 'A1 B 0',
    position: 'relative',
    zIndex: '100',

    '@tabletS': { display: 'none' }
  },

  nav: {
    extend: Flex,

    props: { gap: 'B', align: 'center' },

    childExtend: NavLink,

    ...[{
      props: {
        text: '4IT',
        href: '/about',
        fontWeight: '700'
      }
    }, {
      props: {
        text: 'Market Research',
        href: '/market-research'
      }
    }, {
      extend: DropdownParent,
      tag: 'div',
      props: { padding: 'X2 0' },
      NavLink: {
        href: '/recruiting',
        text: 'Recruiting'
      },
      on: { click: e => e.preventDefault() },
      Dropdown: {
        props: {},
        ListInDropdown: {
          childExtend: {
            extend: NavLink,
            props: {
              fontWeight: '500',
              widthRange: '100%',
              padding: 'Z2 A2'
            }
          },
          ...[{
            props: {
              text: 'Recruiting',
              href: '/recruiting'
            }
          }, {
            props: {
              text: 'RPO',
              href: '/rpo'
            }
          }, {
            props: {
              text: 'Recruiting Partner',
              href: '/recruiting-partner'
            }
          }]
        }
      }
    }, {
    //   props: {
    //     href: '/hiring',
    //     text: 'Open Positions'
    //   }
    // }, {
      props: {
        href: '/contact',
        text: 'Contact'
      }
    }]
  },

  themes: {
    extend: Flex,
    props: () => ({
      style: {
        display: window.location.pathname !== '/' && 'none'
      },
      fontSize: 'A2',
      gap: 'Z2'
    }),

    childExtend: {
      extend: SquareButton,
      props: ({ state, key }) => ({
        transition: '150ms',
        transitionProperty: 'opacity, color, background',
        isActive: state.globalTheme === key,
        isNotActive: state.globalTheme !== key,

        theme: 'sepia',
        background: 'transparent 0',
        opacity: '0.5',

        $isActive: {
          opacity: '1',
          theme: 'sepia .active',
          '@mobileL': { display: 'none' }
        }
      }),
      on: {
        click: (ev, { state, key }) => {
          init({ globalTheme: key })
          state.update({ globalTheme: key })
          setCookie('globalTheme', key, 31)
        }
      }
    },

    light: {
      props: ({ state, key }) => ({
        icon: state.globalTheme === key ? 'sun' : 'sun outline'
      })
    },

    dark: {
      props: ({ state, key }) => ({
        icon: state.globalTheme === key ? 'moon' : 'moon outline'
      })
    }
  },

  button: {
    extend: ButtonCTA,
    props: () => ({
      style: {
        display: window.location.pathname === '/' && 'none'
      },
      '@mobileL': { display: 'none !important' }
    })
  }
}
