'use strict'

import { Flex } from 'smbls'
import { Footer } from './Footer'

const Paragraph = {
  tag: 'p',

  props: {
    lineHeight: '1.5',
    margin: '0',

    '@light': { color: 'grey25' },
    '@dark': { color: 'white .65' }
  },

  state: true,

  text: ({ state }) => state.value
}

export const ArticleHeading = {
  props: {
    maxWidth: 'I1',
    weight: 'regular'
  },
  h1: {
    text: ({ state }) => state.title,
    props: {
      fontSize: 'N',
      fontWeight: '700',
      margin: '0',

      '@mobileL': {
        fontSize: 'L'
      }
    }
  },
  h2: {
    text: ({ state }) => state.description,
    props: { margin: '0', fontWeight: '300' }
  }
}

export const ArticleParagraphs = {
  extend: Flex,
  props: {
    flow: 'column',
    gap: 'A',
    maxWidth: 'H2'
  },
  childExtend: Paragraph,
  $stateCollection: ({ state }) => state.parse()
}

export const ArticleHeadline = {
  tag: 'p',
  props: {
    maxWidth: 'H1',
    margin: '0',
    lineHeight: '1.5',
    fontSize: 'B1'
  },
  childExtend: { tag: 'span', text: ({ state }) => state.value }
}

export const ArticleSection = {
  h1: {
    props: {
      fontSize: 'E2',
      fontWeight: '700',
      margin: '0 0 Y2'
    },
    text: ({ state }) => state.title
  },

  paragraphs: {
    extend: ArticleParagraphs,
    $stateCollection: ({ state }) => state.description
  }
}

export const ArticleFooter = {
  extend: Footer,
  props: {
    padding: 'C1 D',

    '@mobileL': {
      padding: 'C B1 B'
    }
  },

  h2: {
    props: { fontSize: 'G2' }
  },

  p: {
    props: {
      fontWeight: '400',
      lineHeight: '1.563'
    }
  }
}
