'use strict'

import { Flex } from 'smbls'

export const Proposition = {
  extend: Flex,

  state: 'value_proposition',

  props: {
    gap: 'B2',
    flow: 'column',
    margin: 'E1 0 0',
    '@mobileL': { margin: 'D1 0 0' }
  },

  H1: ({ state }) => ({
    fontSize: 'H',
    maxWidth: 'F1',
    lineHeight: 1.3,
    text: state.title,
    '@mobileL': { fontSize: 'C2' }
  }),

  p: null,

  description: {
    extend: Flex,
    state: true,

    props: {
      maxWidth: 'J2',
      flow: 'row wrap',
      gap: 'A1 C1',
      color: 'text',
      lineHeight: '1.5',
      margin: '-X2 0 0',

      '@mobileL': {
        fontSize: 'A',
        flexFlow: 'column',
        margin: '0'
      }
    },

    childExtend: {
      tag: 'p',
      props: {
        widthRange: 'G1 H2',
        flex: '0 1 30%',
        margin: '0',

        '@mobileL': {
          widthRange: 'auto'
        }
      }
    },
    $propsCollection: ({ state }) => state.parse()
  }
}
