'use strict'

export const Layout = {
  props: {
    width: '100%',
    minHeight: '100%',
    flexFlow: 'row',

    '@mobileL': {
      flexFlow: 'column'
    }
  },

  Navbar: {},

  content: {
    props: {
      position: 'relative',
      flex: '1',
      minWidth: '0'
    }
  }
}
