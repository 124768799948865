'use strict'

import { Link } from 'smbls'
import { Article } from '../../components'

export const Contact = {
  extend: Article,

  state: 'contact',

  header: {},

  content: {
    props: { minHeight: '65vh' },

    ArticleHeading: {},

    Iframe: (el, state) => ({
      background: 'white .05',
      src: state.mapLink,
      allowfullscreen: '',
      loading: 'lazy',
      referrerpolicy: 'no-referrer-when-downgrade',
      round: 'A',
      margin: '0 -Z',
      maxWidth: 'J2',
      minWidth: '0',
      border: '0'
    }),

    ArticleHighlights: {
      props: {},
      title: null,
      reasons: {
        childExtend: { 
          extend: Link,
          props: ({ state }) => ({ href: state.href })
        }
      }
    }
  },

  ArticleFooter: {},
  FooterNav: {}
}

// toTitleCase
// toDescriptionCase

// ლინკდინის გვერდი - https://www.linkedin.com/company/4it-recruit-georgia/mycompany/?viewAsMember=true
// მეილი -  recruit@4it.ge

// [4:05 pm, 08/09/2022] Salome Bakuradze: Terminal • ტერმინალი
// 032 212 10 15
// https://maps.app.goo.gl/GE3CxNKMYZqeHM9A8
// [4:05 pm, 08/09/2022] Salome Bakuradze: 36, 34, 0179 Irakli Abashidze Street, Tbilisi
