'use strict'

import { Package } from './Package'

export const Cards = {
  props: {
    flexFlow: 'row',
    maxWidth: 'J2',
    margin: 'A -A',
    gap: 'A',
    '@mobileL': {
      flexFlow: 'column',
      fontSize: 'Z'
    }
  },

  childExtend: Package,

  ...[{
    props: { href: '/recruiting' },
    h4: { text: 'Recruiting' },
    text: 'We deliver great talent at speed, go the extra mile to ensure candidates are the perfect fit for you and find highly skilled professionals to suit your companys’ unique hiring needs.'
  }, {
    props: { href: '/rpo' },
    h4: { text: 'PRO' },
    text: 'RPO in our language means Perfectly Effortless Hire, While Partnering with RPO Model we go all in with you, become your team members with just one click!'
  }, {
    props: { href: '/recruiting-partner' },
    h4: { text: 'Recruitment Partner' },
    text: 'Your recruiter will take no time to onboard and work for you as your own team member, remotely or from your office with a database access to 10 000+ professionals, ready tools and ATS (Application Tracking System) to be used to build your dream team!'
  }]
}

export const ResearchCards = {
  extend: Package,
  props: {
    margin: 'D -A 0',
    maxWidth: 'J2',
    href: '/market-research',
    background: 'researchGradient',

    ':before': {
      background: 'researchGradient'
    },

    ':after': {
      background: 'researchGradientOverlay',
      transition: 'opacity A defaultBezier'
    },

    '@>mobileL': {
      ':hover > div': {
        transform: 'translate3d(-50%, 0, 0)'
      }
    },

    ':hover:after': {
      opacity: 0.35
    },

    h4: { color: 'wine 1 +35' }
  },
  h4: { text: 'Market Research' },
  text: null,
  Flex: {
    childExtend: { tag: 'span' },
    props: {
      gap: 'B2',
      margin: 'A2 - -',
      padding: '- - X2',
      whiteSpace: 'nowrap',
      transition: '18s transform ',
      '@mobileL': { flow: 'column', margin: 'A - -', gap: 'B' },
      childProps: { lineHeight: '0' }
    },
    ...[
      'Pricing and salary ranges available',
      'Tech market overview',
      'IT Companies on the market, number of employees, stacks in use',
      'Existing trends and market strategies used by IT Companies to acquire candidates'
    ]
  }
}
