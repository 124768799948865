'use strict'

import { Button, Flex, Grid, Link } from 'smbls'

const GridProps = {
  extend: Grid,
  props: {
    columns: 'repeat(3, 1fr)',
    gap: '8%',

    '@tabletS': {
      columns: 'repeat(1, 1fr)',
      gap: 'D2',
      rows: 'repeat(auto, 1fr)'
    }
  }
}

const NavItem = {
  extend: [Flex, Link],

  props: ({ state }) => ({
    flow: 'column',
    padding: 'A1 B',
    margin: '-B',
    round: 'B',
    transition: 'background B',
    href: state.href,
    target: state.href && state.href.includes('http') ? '_blank' : undefined,
    color: 'white',

    '@tabletS': {
      display: !state.href && 'none'
    },

    ':hover': {
      background: state.href ? 'white .1' : undefined
    },

    title: {
      fontWeight: '700',
      margin: '0'
    },

    description: {
      fontWeight: '400',
      margin: 'Y 0 B'
    },

    button: {
      background: 'transparent',
      color: 'white',
      alignSelf: 'flex-start',
      fontWeight: '600',
      gap: 'X2',
      margin: 'auto 0 0',
      padding: '0',
      Icon: { name: 'arrow', fontSize: 'X2' }
    }
  }),

  title: { tag: 'h5', text: ({ state }) => state.title },
  description: {
    tag: 'p',
    if: ({ state }) => state.description,
    text: ({ state }) => state.description
  },
  list: {
    tag: 'ul',
    if: ({ state }) => state.list,
    text: ({ state }) => state.description
  },
  button: {
    if: ({ state }) => state.href,
    extend: Button,
    text: 'Learn more'
  }
}

const RowItem = {
  h1: {
    text: ({ state }) => state.title,
    props: {
      fontSize: 'J',
      fontWeight: '100',
      lineHeight: '1.304',
      margin: 'D 0 A',
      '@mobileL': {
        fontSize: 'I'
      },
      '@tabletS': {
        margin: 'B2 0 A'
      }
    }
  },

  grid: {
    extend: GridProps,
    childExtend: NavItem,
    $stateCollection: ({ state }) => state.data
  }
}

export const Navigation = {
  state: 'navigation',

  props: {
    minHeight: '100%',
    width: '100%',
    '@dark': {
      background: 'blue .35'
    },
    '@light': {
      color: 'white',
      background: 'purple'
    }
  },

  container: {
    props: {
      padding: 'C2 D2 E2',
      maxWidth: 'J3',
      minHeight: '100%',
      position: 'relative',
      '@tabletS': {
        padding: 'C D E'
      },
      '@mobileM': {
        padding: 'B B D'
      }
    },

    lines: {
      props: ({ context }) => {
        const { utils } = context
        const { getColor } = utils.scratchSystem
        return {
          position: 'absolute',
          inset: '0 D2',
          zIndex: '0',
          pointerEvents: 'none',
          style: {
            backgroundPosition: '-4.5% center',
            backgroundSize: '36%'
          },
          '@dark': {
            style: {
              backgroundImage: `repeating-linear-gradient(to right, ${getColor('blue92 .05')}, ${getColor('blue92 .05')} 1px, transparent 1px, transparent)`
            }
          },
          '@light': {
            style: {
              backgroundImage: `repeating-linear-gradient(to right, ${getColor('white .1')}, ${getColor('white .1')} 1px, transparent 1px, transparent)`
            }
          },
          '@tabletS': {
            style: {
              backgroundImage: 'none'
            }
          }
        }
      }
    },

    grid: {
      extend: GridProps,
      props: {
        '@tabletS': {
          gap: 'C',
          flexAlign: 'center flex-start'
        }
      },
      childExtend: {
        extend: Link,
        props: {
          fontWeight: '500'
        }
      },
      ...[
        { text: 'Refer a Client', href: '/refer-a-client' },
        { text: 'About', href: '/about' },
        { text: 'Contact', href: '/contact' }
      ].map(props => ({ props }))
    },

    H1: {
      text: 'The smart work of headhunters benefits establishing trust and sustainability',
      fontSize: 'I2',
      maxWidth: 'G2',
      fontWeight: '700',
      lineHeight: '1.304',
      padding: 'B1 0 0',
      '@tabletS': {
        padding: 'A2 0 0'
      },
      '@mobileL': {
        fontSize: 'I'
      }
    },

    rows: {
      childExtend: RowItem,
      $stateCollection: ({ state }) => state.parse()
    }
  }
}
