'use strict'

import { Flex, Input, Form } from 'smbls'
import { toDescriptionCase } from '@symbo.ls/utils'
import { Article } from '../../components'

import friend from './friend'
import client from './client'

export const ReferPage = {
  extend: Article,

  content: {
    props: { margin: 'C - -' },

    ArticleHeading: {},

    Form: {
      extend: [Flex, Form],

      props: {
        fontSize: 'B2',
        spacingRatio: 1.5,
        flow: 'column',
        margin: 'B2 -Z -',
        gap: 'A1',
        align: 'flex-start flex-start',
        action: 'https://formspree.io/f/xrgdyyjg',
        method: 'POST',
        enctype: 'multipart/form-data'
      },

      Flex: {
        props: {
          gap: 'Z2',
          width: '100%',
          flow: 'column'
        },

        childExtend: {
          extend: Input,
          props: ({ key }) => ({
            name: key,
            theme: 'none',
            width: 'G2-default',
            padding: 'Z',
            round: '0',
            border: 'white .2, solid',
            borderWidth: '0 0 1px',
            transition: 'C defaultBezier border',
            placeholder: toDescriptionCase(key).trim(),
            ':error': {
              borderColor: 'red .5'
            },
            ':focus': {
              borderColor: 'white .5',
              outline: 'none'
            },
            ':focus-visible': {
              borderColor: 'white .5',
              outline: 'none'
            }
          })
        }
      },

      ButtonSubmit: {
        margin: 'C1 - -',
        type: 'submit',
        Icon: null,
        text: 'Refer a friend'
      }
    }
  },

  ArticleFooter: {},
  FooterNav: {}
}

export const ReferAFriend = {
  extend: ReferPage,
  header: {},
  ...friend
}

export const ReferAClient = {
  extend: ReferPage,
  header: {},
  ...client
}
