'use strict'

const themeA = { // eslint-disable-line no-unused-vars
  text: 'blue',
  background: 'white',
  border: 'black', // .opacity(0.2),
  active: {},
  helpers: [],
  themes: {},
  inverse: {} // schemeAInverse
}

export const THEME = {}
