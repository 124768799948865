'use strict'

import { Layout } from './components'

export const router = (path, element, state = {}, pushState = true, scrollto = true) => {
  const route = '/' + (path || window.location.pathname).split('/')[1]
  const content = element.routes[route]

  if (content) {
    if (pushState) window.history.pushState(state, null, route)

    element.state.active = route

    element.set({
      key: route,
      extend: Layout,
      Navbar: {},
      content: {
        [route]: { extend: content }
      }
    })

    if (scrollto) element.node.scrollIntoView()
  }
}
