'use strict'

import { Flex } from 'smbls'
import { ArticleHeading, Header } from './'

export const Article = {
  header: { extend: Header },

  content: {
    extend: Flex,

    props: {
      padding: 'D1 E1',
      flow: 'column',
      gap: 'C2',
      '@mobileL': { padding: 'C B' }
    },

    ArticleHeading: { }
  }
}
