'use strict'

import { Link, Pseudo, Clickable } from 'smbls'

export const Package = {
  extend: [Clickable, Link, Pseudo],

  props: {
    position: 'relative',
    display: 'block',
    padding: 'A2 B1 B',
    flex: 1,
    round: 'A1',
    fontWeight: '400',
    overflow: 'hidden',
    background: 'packageGradient',

    ':before, :after': {
      content: '""',
      position: 'absolute',
      inset: '0 0 0 0',
      transition: 'C defaultBezier',
      transitionProperty: 'opacity'
    },

    ':before': {
      background: 'packageGradient',
      opacity: '0'
    },

    ':after': {
      background: 'packageGradient'
    },

    ':hover': {
      ':before': { opacity: '1' }
    },

    '@dark': { color: 'white' },
    '@light': { color: 'black' },

    '@mobileL': { padding: 'A A1 A1 A2' },

    h4: {
      margin: '0 0 W 0',
      fontSize: 'Z',
      textTransform: 'uppercase',
      color: 'blue',

      '@mobileL': { fontSize: 'Y' }
    }
  }
}
