'use strict'

import { Picture } from 'smbls'

export const Partner = {
  extend: Picture,

  props: {
    height: 'B2',
    margin: '0 X2 0 0',
    transition: 'opacity B, filter B',

    '@mobileL': {
      margin: '0'
    },

    '@dark': {
      // opacity: '.5',
      style: { filter: 'grayscale(100%)' },
      ':hover': {
        style: { filter: 'grayscale(0%)' },
        opacity: '1'
      }
    },
    '@light': {
      // opacity: '.8',
      style: { filter: 'grayscale(100%)' },
      ':hover': {
        style: { filter: 'grayscale(0%)' },
        opacity: '.9'
      }
    }
  }
}
